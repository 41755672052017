import { errorCodes } from '@app/arrays/error-code-http.array';
import { ShareInfoService } from '@app/services/share-info/share-info.service';

export function viewError(
    type: 'error' | 'done',
    code: number,
    shareInfoService: ShareInfoService
): void {
    const error = errorCodes[code];
    if (error) {
        shareInfoService.snackVicente$.emit({
            message: errorCodes[code].message_user,
            type,
        });
    } else {
        shareInfoService.snackVicente$.emit({
            message: 'Hubo un problema, inténtalo más tarde.',
            type: 'error',
        });
    }
}

/*

 */
