interface ErrorDetail {
    message: string;
    message_user: string;
    httpCode: number;
}

interface ErrorCodes {
    [key: number]: ErrorDetail;
}

export const errorCodes: ErrorCodes = {
    4100: {
        message: 'The match was created successfully',
        message_user: 'El match se ha creado exitosamente',
        httpCode: 201,
    },
    4101: {
        message: 'User has not publications to exchange',
        message_user: 'La usuario no tiene publicaciones para intercambiar.',
        httpCode: 422,
    },
    4102: {
        message: 'Problem to create the match',
        message_user: 'Problema para crear el match',
        httpCode: 400,
    },
    4103: {
        message: 'The match does not exist',
        message_user: 'El match no existe',
        httpCode: 404,
    },
    4104: {
        message: 'The match has expired by time',
        message_user: 'El match ha expirado por tiempo',
        httpCode: 400,
    },
    4105: {
        message: 'The match has been canceled for excessive attempts',
        message_user: 'El match ha sido cancelado por intentos excesivos.',
        httpCode: 403,
    },
    4106: {
        message: 'The match has been canceled',
        message_user: 'El match ha sido cancelado',
        httpCode: 400,
    },
    4151: {
        message: 'Publication not found',
        message_user: 'Publicación no encontrada',
        httpCode: 404,
    },
    4152: {
        message: 'Maximum of 5 images allowed.',
        message_user: 'Se permiten un máximo de 5 imágenes',
        httpCode: 403,
    },
    4201: {
        message: 'The chat does not exist',
        message_user: 'El chat no existe',
        httpCode: 400,
    },
    4251: {
        message: 'The user already exists',
        message_user: 'El usuario ya existe',
        httpCode: 409,
    },
    4252: {
        message: 'The user does not exist',
        message_user: 'El usuario no existe',
        httpCode: 404,
    },
    4253: {
        message: 'The previous password is incorrect',
        message_user: 'La contraseña es incorrecta',
        httpCode: 400,
    },
    4254: {
        message: 'The email already exists, please log in',
        message_user: 'El email ya existe, por favor iniciar sesión',
        httpCode: 409,
    },
    4255: {
        message: 'The phone already exists, please log in',
        message_user: 'El teléfono ya existe, por favor iniciar sesión',
        httpCode: 409,
    },
    4256: {
        message: 'Problem creating the user, try again',
        message_user: 'Problema al crear el usuario, intente de nuevo',
        httpCode: 500,
    },
    4257: {
        message: 'Problem updating the user, try again',
        message_user: 'Problema de actualización del usuario, intente de nuevo',
        httpCode: 500,
    },
    4258: {
        message: 'The user is already activated',
        message_user: 'El usuario ya esta activado',
        httpCode: 404,
    },
    4301: {
        message: 'The user or password are incorrect.',
        message_user: 'Usuario o contraseña incorrectos',
        httpCode: 404,
    },
    4302: {
        message: 'Problems generating the token.',
        message_user: 'Problema al generar token',
        httpCode: 404,
    },
    4303: {
        message: 'Problems verifying your account.',
        message_user: 'Problemas para verificar su cuenta',
        httpCode: 203,
    },
    4304: {
        message: 'The access has expired, please log in again.',
        message_user:
            'El acceso ha expirado, por favor inicie sesión nuevamente',
        httpCode: 203,
    },
    4305: {
        message: 'Unauthorized user.',
        message_user: 'Usuario no autorizado',
        httpCode: 401,
    },
    4306: {
        message: 'Problems processing the request, please try again later.',
        message_user:
            'Problemas al procesar la solicitud, por favor inténtelo nuevamente más tarde',
        httpCode: 500,
    },
    4351: {
        message: 'Problems generating the activation.',
        message_user: 'Problemas al generar la activación',
        httpCode: 400,
    },
    4352: {
        message: 'The activation not found.',
        message_user: 'El token de activación ya no es valido',
        httpCode: 404,
    },
    4401: {
        message: 'The user is blocked.',
        message_user: 'El usuario está bloqueado',
        httpCode: 403,
    },
    4402: {
        message: 'The user blocked you',
        message_user: 'El usuario te bloqueó',
        httpCode: 403,
    },
    4451: {
        message: 'file type not allowed',
        message_user: 'Tipo de archivo no permitido',
        httpCode: 415,
    },
    4501: {
        message: 'Number alert',
        message_user: 'Alerta de numero',
        httpCode: 429,
    },
    4502: {
        message: 'Alert not found',
        message_user: 'Alerta no encontrada',
        httpCode: 404,
    },
    4551: {
        message: 'refresh token not found',
        message_user: 'Token de actualización no encontrado',
        httpCode: 404,
    },
};
